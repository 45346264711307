import axios from "axios";
import { ThunkAction } from "redux-thunk";
import { Action, ActionCreator, Reducer } from "redux";

import settings from "@settings";
import { IAPIResponse, extractErrorCode } from "@ducks/common";
import { IItem, ITEMTYPE, LANGUAGE, SortOrder } from "../listing/types";

// Action Types

const GET_SIDE_CONTENT_LISTING = "listing/GET_SIDE_CONTENT_LISTING";
const GET_SIDE_CONTENT_LISTING_ERROR = "listing/GET_SIDE_CONTENT_LISTING_ERROR";
const GET_SIDE_CONTENT_LISTING_SUCCESS =
  "listing/GET_SIDE_CONTENT_LISTING_SUCCESS";

// Action Interfaces
interface IGetSideContentListing
  extends Action<typeof GET_SIDE_CONTENT_LISTING> {}
interface IGetSideContentListingError
  extends Action<typeof GET_SIDE_CONTENT_LISTING_ERROR> {
  payload: string;
}
interface IGetSideContentListingSuccess
  extends Action<typeof GET_SIDE_CONTENT_LISTING_SUCCESS> {
  payload: {
    allItems: IItem[];
    items: IItem[];
  };
}

type SideContentListingActionTypes =
  | IGetSideContentListing
  | IGetSideContentListingError
  | IGetSideContentListingSuccess;

interface ISideContentListingState {
  allItems: IItem[];
  items: IItem[];
  loading: boolean;
  error: boolean;
  errorCode: string;
  success: boolean;
}

const initialState: ISideContentListingState = {
  allItems: [],
  items: [],
  loading: false,
  error: false,
  errorCode: "",
  success: false
};

const sideContentListingReducer: Reducer<
  ISideContentListingState,
  SideContentListingActionTypes
> = (state = initialState, action) => {
  switch (action.type) {
    case GET_SIDE_CONTENT_LISTING:
      return {
        ...state,
        loading: true,
        error: false,
        success: false
      };
    case GET_SIDE_CONTENT_LISTING_ERROR: {
      const errorCode = action.payload ? { errorCode: action.payload } : {};
      return {
        ...state,
        ...errorCode,
        loading: false,
        error: true,
        success: false
      };
    }
    case GET_SIDE_CONTENT_LISTING_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
        error: false,
        success: true
      };
    default:
      return state;
  }
};

export default sideContentListingReducer;

export const getSideContentListing: ActionCreator<ThunkAction<
  void,
  ISideContentListingState,
  void,
  | IGetSideContentListing
  | IGetSideContentListingError
  | IGetSideContentListingSuccess
>> = (
  type: ITEMTYPE,
  portal: number | null,
  sortOrder?: SortOrder,
  language?: LANGUAGE
) => async dispatch => {
  dispatch({ type: GET_SIDE_CONTENT_LISTING });

  try {
    // If portal === null, API will not be called and function will be exited.
    if (!portal) return;
    let url = `${settings.rewards.domain}${settings.rewards.list}?type=${type}&sortBy=scheduleFrom`;
    if (sortOrder) url += `&sortOrder=${sortOrder}`;
    if (language) url += `&language=${language}`;
    // Attach portal ID parameter to end of API url
    url += `&portal=${portal}`;

    const response = await axios.get<IAPIResponse<IItem[]>>(url);
    const {
      data: { response: items }
    } = response;
    const filteredItems = items.filter(item => item.isVisibleOnListing);

    dispatch({
      type: GET_SIDE_CONTENT_LISTING_SUCCESS,
      payload: {
        allItems: items,
        items: filteredItems
      }
    });
  } catch (e) {
    const errorCode = extractErrorCode(e);
    dispatch({ type: GET_SIDE_CONTENT_LISTING_ERROR, payload: errorCode });
  }
};
