import React, { FunctionComponent } from "react";
import { useLocation } from "react-router-dom";

import { LiveChat } from "acm-components";

const LiveChatWidget: FunctionComponent = () => {
  const { pathname } = useLocation();
  return <LiveChat path={pathname} />;
};

export default LiveChatWidget;
