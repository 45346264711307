import axios, { AxiosRequestConfig } from "axios";
import { Action, ActionCreator, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";

import settings from "@settings";
import {
  IAPIResponse,
  extractErrorCode,
  extractIntegrationError
} from "@ducks/common";
import {
  ICouponStatus,
  IRedeemCouponRequest,
  IRedemptionResult,
  ICouponStatusState,
  IRedemptionResultState,
  IConfirmationModalState
} from "./types";
import type { AppState } from "@store";
import { dedupe, redirectToLogin } from "@lib/common";
import { isBefore } from "@lib/datetime";
import { localSet, localGet } from "@lib/storage";
import { LOCAL_JWT_TOKEN, LOCAL_JWT_TOKEN_EXPIRY } from "@constants/storage";
import { getStatus, generateJWTSession } from "@ducks/profile";
import { isULMSessionActive } from "@lib/ulmprofiler";

// Action Types
const CHECK_TOKEN = "coupon/CHECK_TOKEN";
export const REQUEST_COUPON_STATUS = "coupon/REQUEST_COUPON_STATUS";
export const REQUEST_COUPON_STATUS_ERROR = "coupon/REQUEST_COUPON_STATUS_ERROR";
export const REQUEST_COUPON_STATUS_SUCCESS =
  "coupon/REQUEST_COUPON_STATUS_SUCCESS";
export const RESET_COUPON_STATUS = "coupon/RESET_COUPON_STATUS";
const REDEEM_COUPON = "coupon/REDEEM_COUPON";
const REDEEM_COUPON_ERROR = "coupon/REDEEM_COUPON_ERROR";
const REDEEM_COUPON_SUCCESS = "coupon/REDEEM_COUPON_SUCCESS";
const RESET_REDEEM_COUPON = "coupon/RESET_REDEEM_COUPON";
const TOGGLE_CONFIRMATION_MODAL = "coupon/TOGGLE_CONFIRMATION_MODAL";
const TOGGLE_VERIFICATION_MODAL = "coupon/TOGGLE_VERIFICATION_MODAL";
const TOGGLE_WHY_NEED_THIS_MODAL = "coupon/TOGGLE_WHY_NEED_THIS_MODAL";
const TOGGLE_SAMPLE_MODAL = "coupon/TOGGLE_SAMPLE_MODAL";
const RESET_CONFIMRATION_MODAL = "coupon/RESET_CONFIMRATION_MODAL";
const TOGGLE_CONFIRMATION_ERROR_MESSAGE =
  "coupon/TOGGLE_CONFIRMATION_ERROR_MESSAGE";

// Action Interfaces
interface ICheckToken extends Action<typeof CHECK_TOKEN> {
  payload?: string;
}
export interface IRequestCouponStatus
  extends Action<typeof REQUEST_COUPON_STATUS> {}
export interface IRequestCouponStatusError
  extends Action<typeof REQUEST_COUPON_STATUS_ERROR> {
  payload: string;
}
export interface IRequestCouponStatusSuccess
  extends Action<typeof REQUEST_COUPON_STATUS_SUCCESS> {
  payload: ICouponStatus;
}
export interface IResetCouponStatus
  extends Action<typeof RESET_COUPON_STATUS> {}
interface IRedeemCoupon extends Action<typeof REDEEM_COUPON> {}
interface IRedeemCouponError extends Action<typeof REDEEM_COUPON_ERROR> {
  payload: {
    errorCode: string;
    code?: string;
    integrationType?: string;
    reason?: string;
    context?: any;
  };
}
interface IRedeemCouponSuccess extends Action<typeof REDEEM_COUPON_SUCCESS> {
  payload: {
    result: IRedemptionResult;
    code: string;
  };
}
export interface IResetRedeemCoupon
  extends Action<typeof RESET_REDEEM_COUPON> {}
interface IToggleConfirmationModal
  extends Action<typeof TOGGLE_CONFIRMATION_MODAL> {
  payload: boolean;
}
interface IResetConfirmationModal
  extends Action<typeof RESET_CONFIMRATION_MODAL> {}

interface IToggleConfirmationErrorMessage
  extends Action<typeof TOGGLE_CONFIRMATION_ERROR_MESSAGE> {
  error: boolean;
  errorMessage: string;
}

interface IToggleVerificationModal
  extends Action<typeof TOGGLE_VERIFICATION_MODAL> {
  payload: boolean;
}
interface IToggleWhyNeedThisModal
  extends Action<typeof TOGGLE_WHY_NEED_THIS_MODAL> {
  payload: boolean;
}
interface IToggleSampleModal extends Action<typeof TOGGLE_SAMPLE_MODAL> {
  payload: boolean;
}

type CouponActionTypes =
  | ICheckToken
  | IRequestCouponStatus
  | IRequestCouponStatusError
  | IRequestCouponStatusSuccess
  | IResetCouponStatus
  | IRedeemCoupon
  | IRedeemCouponError
  | IRedeemCouponSuccess
  | IResetRedeemCoupon
  | IToggleConfirmationModal
  | IToggleVerificationModal
  | IToggleWhyNeedThisModal
  | IToggleSampleModal
  | IResetConfirmationModal
  | IToggleConfirmationErrorMessage;

// Reducers
interface ICouponState {
  couponImage: string | null;
  confirmRedeemModal: boolean;
  verificationModal: boolean;
  sampleModal: boolean;
  whyNeedThisModal: boolean;
  status: ICouponStatusState;
  redemption: IRedemptionResultState;
  canAvail: boolean;
  couponValidity: number;
  coupon: string;
  createdAt: string;
  expiresAt: string;
  remainingTimeInMilli: number;
  token: string;
  redeemedItems: string[];
  confirmationWindow: IConfirmationModalState;
}

const initialState: ICouponState = {
  confirmRedeemModal: false,
  verificationModal: false,
  sampleModal: false,
  whyNeedThisModal: false,
  status: {
    loading: false,
    error: false,
    success: false,
    reason: "",
    timestamp: 0
  },
  redemption: {
    loading: false,
    error: false,
    errorCode: "",
    success: false,
    integrationType: "",
    reason: "",
    context: {}
  },
  canAvail: false,
  couponValidity: 0,
  coupon: "",
  createdAt: "",
  expiresAt: "",
  remainingTimeInMilli: 0,
  couponImage: null,
  token: "",
  redeemedItems: [],
  confirmationWindow: {
    reset: true,
    error: false,
    errorMessage: ""
  }
};

const couponReducer: Reducer<ICouponState, CouponActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case CHECK_TOKEN:
      return {
        ...state,
        token: action.payload || state.token
      };
    case REQUEST_COUPON_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          loading: true,
          error: false,
          success: false,
          reason: ""
        }
      };
    case REQUEST_COUPON_STATUS_ERROR:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: true,
          success: false,
          reason: action.payload
        }
      };
    case REQUEST_COUPON_STATUS_SUCCESS: {
      const reason = action.payload.reason || "";
      const timestamp = action.payload.timestamp || state.status.timestamp;
      return {
        ...state,
        status: {
          loading: false,
          error: false,
          success: true,
          reason,
          timestamp
        },
        couponValidity: 0,
        canAvail: false,
        reason: "",
        coupon: "",
        createdAt: "",
        expiresAt: "",
        remainingTimeInMilli: 0,
        token: "",
        ...action.payload
      };
    }
    case RESET_COUPON_STATUS:
      return {
        ...state,
        status: {
          ...state.status,
          loading: false,
          error: false,
          success: false,
          reason: ""
        },
        couponValidity: 0,
        canAvail: false,
        reason: "",
        coupon: "",
        createdAt: "",
        expiresAt: "",
        remainingTimeInMilli: 0,
        token: ""
      };

    case RESET_REDEEM_COUPON:
      return {
        ...state,
        redemption: {
          loading: false,
          error: false,
          errorCode: "",
          success: false,
          integrationType: "",
          reason: "",
          context: {}
        }
      };
    case REDEEM_COUPON:
      return {
        ...state,
        redemption: {
          loading: true,
          error: false,
          errorCode: "",
          success: false,
          integrationType: "",
          reason: "",
          context: {}
        }
      };
    case REDEEM_COUPON_ERROR: {
      const {
        errorCode,
        code,
        reason,
        context,
        integrationType
      } = action.payload;
      return {
        ...state,
        redemption: {
          loading: false,
          error: true,
          errorCode,
          success: false,
          integrationType: integrationType ? integrationType : "",
          reason: reason ? reason : "",
          context: context ? context : {}
        },
        redeemedItems: code
          ? dedupe([...state.redeemedItems, code])
          : state.redeemedItems
      };
    }
    case REDEEM_COUPON_SUCCESS: {
      const { result, code } = action.payload;
      return {
        ...state,
        redemption: {
          loading: false,
          error: false,
          errorCode: "",
          success: true,
          integrationType: "",
          reason: "",
          context: {}
        },
        coupon: result.coupon,
        couponValidity: result.couponValidity,
        createdAt: result.createdAt,
        expiresAt: result.expiresAt,
        remainingTimeInMilli: result.remainingTimeInMilli,
        token: result.token,
        redeemedItems: dedupe([...state.redeemedItems, code])
      };
    }
    case TOGGLE_CONFIRMATION_MODAL:
      return {
        ...state,
        confirmRedeemModal: action.payload
      };
    case TOGGLE_VERIFICATION_MODAL:
      return {
        ...state,
        verificationModal: action.payload,
        redemption: {
          loading: false,
          error: false,
          errorCode: "",
          success: false
        }
      };
    case TOGGLE_WHY_NEED_THIS_MODAL:
      return {
        ...state,
        whyNeedThisModal: action.payload
      };
    case TOGGLE_SAMPLE_MODAL:
      return {
        ...state,
        sampleModal: action.payload
      };
    case RESET_CONFIMRATION_MODAL:
      return {
        ...state,
        confirmationWindow: {
          reset: true,
          error: false,
          errorMessage: ""
        }
      };
    case TOGGLE_CONFIRMATION_ERROR_MESSAGE:
      return {
        ...state,
        confirmationWindow: {
          reset: false,
          error: action.error,
          errorMessage: action.errorMessage
        }
      };
    default:
      return state;
  }
};

export default couponReducer;

// Actions
export const checkToken: ActionCreator<ICheckToken> = () => {
  const token = localGet(LOCAL_JWT_TOKEN);
  const expiry = localGet(LOCAL_JWT_TOKEN_EXPIRY) || "";
  if (token && isBefore(new Date().toISOString(), expiry))
    return { type: CHECK_TOKEN, payload: token };
  else return { type: CHECK_TOKEN };
};

// export const requestCouponStatus: ActionCreator<ThunkAction<
//   void,
//   ICouponState,
//   void,
//   IRequestCouponStatus | IRequestCouponStatusError | IRequestCouponStatusSuccess
// >> = (request: ICouponStatusRequest, token?: string) => async (
//   dispatch
// ): Promise<void> => {
//   dispatch({ type: REQUEST_COUPON_STATUS });
//   try {
//     const url = `${settings.rewards.domain}${settings.rewards.redeemStatus}`;
//     // const token = cookieGet(`${jwtTokenCookieName}:${request.rewardCode}`);
//     const requestConfig: AxiosRequestConfig = {};
//     if (token) requestConfig.headers = { Authorization: `Bearer ${token}` };
//     const response = await axios.post<IAPIResponse<ICouponStatus>>(
//       url,
//       request,
//       requestConfig
//     );
//     const result = response.data.response;
//     dispatch({ type: REQUEST_COUPON_STATUS_SUCCESS, payload: result });
//   } catch (e) {
//     const reason = extractErrorCode(e);
//     dispatch({ type: REQUEST_COUPON_STATUS_ERROR, payload: reason });
//   }
// };

export const resetCouponStatus: ActionCreator<IResetCouponStatus> = () => ({
  type: RESET_COUPON_STATUS
});

export const resetRedeemCoupon: ActionCreator<IResetRedeemCoupon> = () => ({
  type: RESET_REDEEM_COUPON
});

export const redeemCoupon: ActionCreator<ThunkAction<
  void,
  AppState,
  void,
  IRedeemCoupon | IRedeemCouponError | IRedeemCouponSuccess
>> = (request: IRedeemCouponRequest, token?: string) => async (
  dispatch,
  getState
): Promise<void> => {
  dispatch({ type: REDEEM_COUPON });

  let currentRewardCode: string | undefined = undefined;
  try {
    const url = `${settings.rewards.domain}${settings.rewards.redeem}`;
    const requestConfig: AxiosRequestConfig = {};
    const selectedSmartCard = getState().profile.selectedSmartCard;

    const loginUrl = getState().profile.loginUrl;
    const isLoggedIn = getState().profile.isLoggedIn;

    currentRewardCode = getState().listing.details?.code;
    // Checks for token expiry and regeneration if currently loggedin
    // We can check if user logged out. But There is no event for when cookie expires. So we need to explicitely
    // Check whether user is logged in but their cookie expires.
    // ULM session is valid for 1 day, so if it's expired, we redirect to login
    if (isLoggedIn && !isULMSessionActive()) {
      return redirectToLogin(loginUrl);
    }

    if (token) {
      requestConfig.headers = { Authorization: `Bearer ${token}` };
    } else if (selectedSmartCard) {
      // If JWT token is expired, we need to regenrate before redemption attempt
      const sessionToken = await dispatch(
        generateJWTSession(selectedSmartCard)
      );

      requestConfig.headers = { Authorization: `Bearer ${sessionToken}` };
    }

    const response = await axios.post<IAPIResponse<IRedemptionResult>>(
      url,
      request,
      requestConfig
    );
    const result = response.data.response;
    localSet(LOCAL_JWT_TOKEN, result.token);
    localSet(LOCAL_JWT_TOKEN_EXPIRY, result.expiresAt);

    /**
     * Check whether user is still on the same reward where this redeem originated
     * If on the same page, we proceed with state changes
     * If not on the same page, we abort any state  changes
     *
     * e.g. User presses back  button after calling redeem. So  there is a possibility this redeem request completes
     * after user lands on a different details page.
     */
    if (currentRewardCode !== getState().listing.details?.code) {
      return;
    }

    dispatch(
      getStatus(
        request.rewardCode,
        Number(request.portal),
        getState().profile.smartCards
      )
    );
    dispatch({
      type: REDEEM_COUPON_SUCCESS,
      payload: {
        result: result,
        code: request.rewardCode
      }
    });
  } catch (e) {
    const errorCode = extractErrorCode(e);
    const payload: any = { errorCode };
    if (errorCode === "ALREADY_REDEEM") {
      payload.code = request.rewardCode;
    } else if (errorCode === "THIRD_PARTY_INTEGRATION_FAILED") {
      const integrationError = extractIntegrationError(e);
      if (integrationError) {
        const {
          integrationType,
          reason,
          context
        } = integrationError.errorAttributes;
        payload.integrationType = integrationType;
        payload.reason = reason;
        payload.context = context;
      }
    }

    /**
     * Checks whether user still on the  same page when this  request returns
     * to avoid race condition when user goes to another details page
     */
    if (currentRewardCode !== getState().listing.details?.code) {
      return;
    }

    dispatch(
      getStatus(
        request.rewardCode,
        Number(request.portal),
        getState().profile.smartCards
      )
    );
    dispatch({ type: REDEEM_COUPON_ERROR, payload });
  }
};

export const toggleRedeemConfirmationModal: ActionCreator<IToggleConfirmationModal> = (
  state: boolean
) => ({
  type: TOGGLE_CONFIRMATION_MODAL,
  payload: state
});

export const toggleVerificationModal: ActionCreator<IToggleVerificationModal> = (
  state: boolean
) => ({
  type: TOGGLE_VERIFICATION_MODAL,
  payload: state
});

export const toggleWhyNeedThisModal: ActionCreator<IToggleWhyNeedThisModal> = (
  state: boolean
) => ({
  type: TOGGLE_WHY_NEED_THIS_MODAL,
  payload: state
});

export const toggleSampleModal: ActionCreator<IToggleSampleModal> = (
  state: boolean
) => ({
  type: TOGGLE_SAMPLE_MODAL,
  payload: state
});

export const resetConfirmationModal: ActionCreator<IResetConfirmationModal> = () => ({
  type: RESET_CONFIMRATION_MODAL
});

export const toggleErrorMessageInConfirmationModal: ActionCreator<IToggleConfirmationErrorMessage> = (
  error: boolean,
  errorMessage: string
) => ({
  type: TOGGLE_CONFIRMATION_ERROR_MESSAGE,
  error,
  errorMessage
});
