/* eslint-disable max-len */
import axios, { AxiosRequestConfig } from "axios";
import { Action, ActionCreator, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";
import type {
  IULMProfile,
  IULMAuthResponse,
  ISmartCardsStatus,
  IStatusResponse,
  SmartCardStatus,
  IULMProfileV2,
} from "./types";
import { cookieGet, cookieRemoveRegEx, cookieRemove } from "@lib/storage";
import {
  IRequestCouponStatus,
  IRequestCouponStatusSuccess,
  IRequestCouponStatusError,
  IResetCouponStatus,
  REQUEST_COUPON_STATUS,
  REQUEST_COUPON_STATUS_ERROR,
  REQUEST_COUPON_STATUS_SUCCESS,
} from "@ducks/coupon";
import { ICouponStatus } from "@ducks/coupon/types";
import {
  extractSmartCard,
  setJWTSessionToken,
  isJWTSessionActive,
  getJWTSessionToken,
} from "@lib/ulmprofiler";
import { AppState } from "@store";
import isEmpty from "lodash.isempty";
import { IAPIResponse, extractErrorCode } from "@ducks/common";
import settings from "@settings";
import { ULM_ACCESS_TOKEN_COOKIE_NAME } from "@constants/storage";

// Action Types
const SET_ACCOUNT_NO = "profile/SET_ACCOUNT_NO";
const SET_LIST_SMARTCARD = "profile/SET_LIST_SMARTCARD";
const SET_SMARTCARD = "profile/SET_SMARTCARD";
const CHECK_LOGGED_IN = "profile/CHECK_LOGGED_IN";
const GENERATE_JWT_SESSION = "profile/GENERATE_JWT_SESSION";
const SET_SMARTCARD_STATUS = "profile/SET_SMARTCARD_STATUS";
const RESET_SMARTCARD_STATUS = "profile/RESET_SMARTCARD_STATUS";
const SET_LOGIN_URL = "profile/SET_LOGIN_URL";

// Action Interfaces
interface ILoginPayload {
  ulmProfile: IULMProfile | undefined;
  isLoggedIn: boolean;
}

interface ISetListSmartCard extends Action<typeof SET_LIST_SMARTCARD> {
  payload: string[];
}
interface ISetAccountNo extends Action<typeof SET_ACCOUNT_NO> {
  payload: string[];
}
interface ISetSmartCard extends Action<typeof SET_SMARTCARD> {
  payload: string;
}
interface ICheckLoggedIn extends Action<typeof CHECK_LOGGED_IN> {
  payload: ILoginPayload;
}
interface IGenerateJWTSession extends Action<typeof GENERATE_JWT_SESSION> {}
interface ISetSmartCardStatus extends Action<typeof SET_SMARTCARD_STATUS> {
  payload: ISmartCardsStatus;
}
interface IResetSmartCardStatus extends Action<typeof RESET_SMARTCARD_STATUS> {}
interface ISetLoginUrl extends Action<typeof SET_LOGIN_URL> {
  payload: string;
}

type IProfileActionTypes =
  | ISetSmartCard
  | ISetListSmartCard
  | ISetAccountNo
  | ICheckLoggedIn
  | IGenerateJWTSession
  | ISetSmartCardStatus
  | IRequestCouponStatus
  | IRequestCouponStatusSuccess
  | IRequestCouponStatusError
  | IResetSmartCardStatus
  | ISetLoginUrl
  | IResetCouponStatus;

// Reducer

export interface IProfileState {
  readonly displayAccountId: string | undefined;
  readonly smartCards: string[];
  readonly selectedSmartCard: string;
  readonly isLoggedIn: boolean;
  readonly smartCardsStatus: ISmartCardsStatus;
  readonly loginUrl: string;
  readonly ulmProfile: IULMProfile | undefined;
}

const initialState: IProfileState = {
  displayAccountId: undefined,
  smartCards: [],
  selectedSmartCard: "",
  isLoggedIn: false,
  smartCardsStatus: {},
  loginUrl: "",
  ulmProfile: undefined,
};

const profileReducer: Reducer<IProfileState, IProfileActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SET_ACCOUNT_NO:
      return {
        ...state,
        ...action.payload,
      };

    case SET_LIST_SMARTCARD:
      return {
        ...state,
        smartCards: action.payload,
      };

    case SET_SMARTCARD:
      return {
        ...state,
        selectedSmartCard: action.payload,
      };

    case CHECK_LOGGED_IN:
      return {
        ...state,
        ...action.payload,
      };

    case SET_SMARTCARD_STATUS:
      return {
        ...state,
        smartCardsStatus: action.payload,
      };

    case RESET_SMARTCARD_STATUS:
      return {
        ...state,
        smartCardsStatus: {},
      };

    case SET_LOGIN_URL:
      return {
        ...state,
        loginUrl: action.payload,
      };

    default:
      return state;
  }
};

export default profileReducer;

// Actions
export const getSmartCards: ActionCreator<
  ThunkAction<void, AppState, void, IProfileActionTypes>
> =
  () =>
  async (dispatch, getState): Promise<void> => {
    try {
      const ulmProfile = getState().profile.ulmProfile as IULMProfile;
      const smartcards = extractSmartCard(ulmProfile);

      // Initialise selected smart card to first active smartcard
      if (
        getState().profile.selectedSmartCard === "" &&
        smartcards.length > 0
      ) {
        await dispatch({
          type: SET_SMARTCARD,
          payload: smartcards[0],
        });
      }

      dispatch({
        type: SET_LIST_SMARTCARD,
        payload: smartcards,
      });
    } catch (error) {
      dispatch({
        type: SET_LIST_SMARTCARD,
        payload: [],
      });
    }
  };

export const getUserInfo: ActionCreator<
  ThunkAction<void, AppState, void, any>
> =
  () =>
  async (dispatch, getState): Promise<void> => {
    try {
      const authToken = cookieGet(ULM_ACCESS_TOKEN_COOKIE_NAME);
      const isLoggedIn = getState().profile.isLoggedIn;

      const url = settings.rewards.getUserInfoUrl;
      
      const requestConfig: AxiosRequestConfig = authToken
        ? {
            headers: {
              Authorization: authToken
            },
          }
        : {};

      const { data } = await axios.post<IAPIResponse<IULMProfileV2>>(
        url,
        { platform: "shop" },
        requestConfig
      );

      isLoggedIn &&
        dispatch({
          type: SET_ACCOUNT_NO,
          payload: {
            displayAccountId: data.response.profile.user.displayAccountId,
          },
        });
    } catch (error) {
      dispatch({
        type: SET_ACCOUNT_NO,
        payload: {
          displayAccountId: null,
        },
      });
    }
  };

export const setSmartCard: ActionCreator<IProfileActionTypes> = (
  smartcard: string
) => {
  return {
    type: SET_SMARTCARD,
    payload: smartcard,
  };
};

export const checkLoggedIn: ActionCreator<
  ThunkAction<void, void, void, IProfileActionTypes>
> =
  (ulmProfile: IULMProfile) =>
  async (dispatch): Promise<void> => {
    let isLoggedIn;
    if (!ulmProfile) {
      cookieRemoveRegEx(/rewards_token_\d{4}/);
      dispatch({
        type: RESET_SMARTCARD_STATUS,
      });
      isLoggedIn = false;
    } else {
      isLoggedIn = true;
    }
    dispatch({
      type: CHECK_LOGGED_IN,
      payload: {
        isLoggedIn,
        ulmProfile,
      },
    });
  };

export const generateJWTSession: ActionCreator<
  ThunkAction<void, AppState, void, IProfileActionTypes>
> =
  (smartcard: string) =>
  async (dispatch, getState): Promise<string | null> => {
    try {
      const ulmProfile = getState().profile.ulmProfile as IULMProfile;
      const ulmProfileToken = ulmProfile
        ? ulmProfile.ulmProfileToken
        : undefined;

      const authToken = cookieGet(ULM_ACCESS_TOKEN_COOKIE_NAME);
      if (!authToken) throw new TypeError("Missing ULM access_token");

      if (isJWTSessionActive(smartcard)) {
        return getJWTSessionToken(smartcard);
      }
      const url = `${settings.rewards.domain}${settings.rewards.authenticate}`;
      const requestConfig: AxiosRequestConfig = {
        headers: {
          Authorization: `Bearer ${ulmProfileToken}`,
        },
      };

      const { data } = await axios.post<IAPIResponse<IULMAuthResponse>>(
        url,
        { smartcardNumber: smartcard },
        requestConfig
      );
      const sessionToken = data.response.token;
      setJWTSessionToken(smartcard, sessionToken, 60);

      return sessionToken;
    } catch (error) {
      const errorCode = extractErrorCode(error);
      if (errorCode === "INVALID_TOKEN") {
        cookieRemove("ulm");
        cookieRemove(ULM_ACCESS_TOKEN_COOKIE_NAME);
      }
    }

    return null;
  };

export const getStatus: ActionCreator<
  ThunkAction<void, AppState, void, IProfileActionTypes>
> =
  (rewardCode: string, portal: number, smartcards: string[]) =>
  async (dispatch, getState): Promise<void> => {
    const timestamp = new Date().getTime();
    dispatch({
      type: REQUEST_COUPON_STATUS,
    });
    try {
      const ulmProfile = getState().profile.ulmProfile as IULMProfile;
      const ulmProfileToken = ulmProfile
        ? ulmProfile.ulmProfileToken
        : undefined;

      const authToken = cookieGet(ULM_ACCESS_TOKEN_COOKIE_NAME);

      const isLoggedIn = getState().profile.isLoggedIn;

      const isSMCAvailable = smartcards.length > 0;
      const url = `${settings.rewards.domain}${settings.rewards.redeemStatus}`;

      const requestConfig: AxiosRequestConfig = authToken
        ? {
            headers: {
              Authorization:
                authToken && isSMCAvailable
                  ? `Bearer ${ulmProfileToken}`
                  : null,
            },
          }
        : {};

      if (
        isLoggedIn &&
        smartcards.length === 0 &&
        timestamp >= getState().coupon.status.timestamp
      ) {
        dispatch({
          type: REQUEST_COUPON_STATUS_SUCCESS,
          payload: {
            canAvail: false,
            couponValidity: 0,
            reason: "NO_VALID_SMC",
            timestamp,
          },
        });
      } else {
        const { data } = await axios.post<IAPIResponse<IStatusResponse>>(
          url,
          authToken && isSMCAvailable
            ? { rewardCode, portal, smartcards }
            : { rewardCode, portal },
          requestConfig
        );
        const recordStatus = {
          ...data.response.recordStatus,
          couponValidity: data.response.couponValidity,
        } as ICouponStatus;
        const smcStatus = data.response.smcStatus;
        if (
          !smcStatus &&
          recordStatus &&
          timestamp >= getState().coupon.status.timestamp
        ) {
          dispatch({
            type: REQUEST_COUPON_STATUS_SUCCESS,
            payload: { ...recordStatus, timestamp },
          });
        } else if (smcStatus) {
          Object.keys(smcStatus).map((key) => {
            if (smcStatus[key].canAvail && !recordStatus.canAvail) {
              smcStatus[key] = {
                ...smcStatus[key],
                canAvail: false,
                reason: recordStatus.reason as SmartCardStatus,
              };
            }
            smcStatus[key] = {
              ...smcStatus[key],
              couponValidity: data.response.couponValidity,
            };
          });
          dispatch({
            type: SET_SMARTCARD_STATUS,
            payload: { ...data.response.smcStatus },
          });
        }
      }
    } catch (error) {
      const reason = extractErrorCode(error);
      dispatch({ type: REQUEST_COUPON_STATUS_ERROR, payload: reason });
      dispatch({
        type: SET_SMARTCARD_STATUS,
        payload: {},
      });
    }
  };

export const setCouponStatus: ActionCreator<
  ThunkAction<void, AppState, void, IProfileActionTypes>
> =
  () =>
  async (dispatch, getState): Promise<void> => {
    const selectedSmartCard = getState().profile.selectedSmartCard;
    const smartCardsStatus = getState().profile.smartCardsStatus;
    const timestamp = new Date().getTime();
    if (selectedSmartCard !== "" && !isEmpty(smartCardsStatus)) {
      const payload = smartCardsStatus[selectedSmartCard] as ICouponStatus;
      dispatch({
        type: REQUEST_COUPON_STATUS_SUCCESS,
        payload: { ...payload, timestamp },
      });
    } else {
      dispatch({
        type: REQUEST_COUPON_STATUS_ERROR,
        payload: "No smartcard status available",
      });
    }
  };

export const resetSmartCardStatus: ActionCreator<IProfileActionTypes> = () => {
  return {
    type: RESET_SMARTCARD_STATUS,
  };
};

export const setSmartCardsStatus: ActionCreator<IProfileActionTypes> = (
  smartCardStatus: ISmartCardsStatus
) => {
  return {
    type: SET_SMARTCARD_STATUS,
    payload: smartCardStatus,
  };
};

export const setLoginUrl: ActionCreator<IProfileActionTypes> = (
  loginUrl: string
) => {
  return {
    type: SET_LOGIN_URL,
    payload: loginUrl,
  };
};
