import React from "react";
import { GlobalStyles, useTheme } from "@mui/material";

import "./fonts.css";

const AcmGlobalStyles: React.FC = () => {
  const { palette } = useTheme();
  return (
    <GlobalStyles
      styles={{
        html: {
          WebkitTextSizeAdjust: "100%",
          MozTextSizeAdjust: "100%",
          scrollBehavior: "smooth"
        },
        "html, body": {
          margin: 0,
          padding: 0
        },
        a: {
          color: "inherit",
          textDecoration: "none",
          cursor: "pointer"
        },
        ul: {
          margin: 0,
          paddingLeft: 0,
          listStyle: "none"
        },
        ol: {
          margin: 0
        },
        li: {
          fontSize: "1rem",
          lineHeight: "1.375rem"
        },
        b: {
          fontWeight: "bold"
        },
        strong: {
          fontWeight: "800"
        },
        "@keyframes shimmer": {
          "0%": {
            backgroundPosition: "-100% 0"
          },
          "100%": {
            backgroundPosition: "200% 0"
          }
        },
        s: {
          color: palette.grey[600]
        }
      }}
    />
  );
};

export default AcmGlobalStyles;
