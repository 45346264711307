import axios from "axios";
import { Action, ActionCreator, Reducer } from "redux";
import { ThunkAction } from "redux-thunk";
import get from "lodash/get";

import settings from "@settings";
import { store } from "../../index";
import {
  IAppConfig,
  IListingPage,
  IVerificationPage,
  ILanguages,
  ILanguage,
  IMetaConfig,
  IResizerConfig,
  IPortalConfig,
} from "./types";
import { IFortressResponse, extractConfig } from "@lib/fortress";

// Environment variables
export const PORTAL = process.env.PORTAL;

// Action Types
const GET_CONFIG = "config/GET_CONFIG";
const GET_CONFIG_ERROR = "config/GET_CONFIG_ERROR";
const GET_CONFIG_SUCCESS = "config/CONFIG_LOADED";

// Action Interface
interface IConfigRequest extends Action<typeof GET_CONFIG> {}
interface IConfigError extends Action<typeof GET_CONFIG_ERROR> {}
interface IConfigSuccess extends Action<typeof GET_CONFIG_SUCCESS> {
  payload: IConfigPayload;
}
interface IConfigPayload {
  app?: IAppConfig;
  listing?: IListingPage;
  verification?: IVerificationPage;
  language?: ILanguage;
  meta?: IMetaConfig;
  resizer?: IResizerConfig;
  portal?: number | null;
}
type ConfigActionTypes = IConfigRequest | IConfigError | IConfigSuccess;

// Reducers
interface IConfigState {
  loading: boolean;
  error: boolean;
  app: IAppConfig;
  listing: IListingPage;
  verification: IVerificationPage;
  language: ILanguage;
  meta: IMetaConfig;
  resizer: IResizerConfig;
  portal: number | null;
}

const initialState: IConfigState = {
  loading: false,
  error: false,
  app: {
    maintenanceMode: false,
    redemptionMode: true,
    logoUrl: "",
    termsLink: "",
    privacyLink: "",
    couponFallbackImage: "",
    couponFallbackLink: "",
    isApp: false,
  },
  listing: {
    maxSideContent: 0,
    isLogoVisible: true,
    BANNER_IMAGE_TABLET: "",
    BANNER_IMAGE_DESKTOP: "",
    BANNER_IMAGE_MOBILE: "",
    isTitleVisible: true,
    headerContentGrid: 5,
    headerImageGrid: 7,
    isSortEnabled: true,
    sortOptions: [],
    categoryOptions: [],
    defaultSort: "featured",
    defaultPageSize: 0,
    dataConfig: {
      category: 0,
      portal: 0,
    },
    videoControls: {
      url: "",
      playing: true,
      muted: true,
      uiLogo: false,
      uiStartScreenInfo: false,
      playlist: "",
      sharingEnable: false,
    },
    reCaptchaSiteKey: "",
    shortFormUrl: "",
  },
  verification: {
    astroLogo: "",
    defaultIdentification: "",
    identificationOptions: [],
    identificationRegex: "",
    accountNumberRegex: "",
    accountNumberSampleImage: "",
    smcSampleImage: "",
  },
  language: {
    HOME_TITLE: "",
    HOME_SUBTITLE: "",
    BREADCRUMBS_PARENT_REWARDS: "",
    BREADCRUMBS_PARENT_PROMOTIONS: "",
    WHATS_NEW: "",
    NO_REWARDS: "",
    REDEEM_ONLINE: "",
    REDEEM_AT_PARTICIPATING_STORE: "",
    PROMOTION: "",
    FLASH_SALE: "",
    FLASH_SALE_STARTS_IN: "",
    BACK_TO_HOME: "",
    GO_TO_HOME: "",
    RELOAD: "",
    GENERIC_ERROR_TITLE: "",
    GENERIC_ERROR_MESSAGE: "",
    GENERIC_ERROR_MESSAGE_2: "",
    PAGE_NOT_FOUND_TITLE: "",
    PAGE_NOT_FOUND_MESSAGE: "",
    MAINTENANCE_MODE_TITLE: "",
    MAINTENANCE_MODE_MESSAGE: "",
    SIDE_CONTENT_TITLE: "",
    OK: "",
    category: {
      ALL: "",
      PRODUCT_AND_SERVICES: "",
      CONTEST_AND_EVENTS: "",
      FOR_NEW_CUSTOMERS: "",
      FOR_EXISTING_CUSTOMERS: "",
    },
    sort: {
      FEATURED: "",
      LATEST: "",
      EXPIRY: "",
      A_TO_Z: "",
      Z_TO_A: "",
    },
    details: {
      FLASH_SALE_PRICE: "",
      RM: "",
      REDEMPTION_PERIOD: "",
      WEBSITE: "",
      VISIT_LINK: "",
      LOCATION: "",
      DEFAULT_LOCATION_TEXT: "",
      PROMOTION_PERIOD: "",
      OFFER_CODE: "",
      DESCRIPTIONS: "",
      REDEEM: "",
      REDEEMED: "",
      SHOW: "",
      TERMS_AND_CONDITIONS: "",
      APPLY: "",
      JOIN_NOW: "",
      THIRD_PARTY_CONFIRMATION_MODAL_TITLE: "",
      REDEEM_OFFER_NOW: "",
      REDEEM_MODAL_MESSAGE: "",
      CANCEL: "",
      PROCEED: "",
      CHECKING_COUPON_DETAILS: "",
      FLASH_SALE_STARTS_IN: "",
      REDEMPTION_PERIOD_NOT_STARTED: "",
      PROMOTION_REDEMPTION_PERIOD_NOT_STARTED: "",
      REDEMPTION_PERIOD_HAS_EXPIRED: "",
      PROMOTION_REDEMPTION_PERIOD_HAS_EXPIRED: "",
      OFFER_IS_VALID: "",
      UNLIMITED_OFFER_IS_VALID: "",
      TIME_LEFT_TO_REDEEM: "",
      YOUR_COUPON_NOT_VALID: "",
      YOUR_OFFER_IS_READY: "",
      HIDE: "",
      COPY_CODE: "",
      CODE_COPIED: "",
      SHOP_NOW: "",
      REDEEM_ONLINE_HEADER: "",
      REDEEM_AT_PARTICIPATING_STORE_HEADER: "",
      IMAGE_REDEEM_AT_PARTICIPATING_STORE_HEADER: "",
      ALREADY_REDEEMED_MODAL_HEADER: "",
      ALREADY_REDEEMED_MODAL_MESSAGE: "",
      THANK_YOU_MODAL_HEADER: "",
      REDEEM_PROCESSING_MESSAGE: "",
      CODE_INPUT_ERROR_MESSAGE: "",
      CODE_INPUT_PLACEHOLDER: "",
      CODE_INPUT_LABEL: "",
      IF_ANY: "",
      OTHERS: "",
    },
    verification: {
      VERIFY_ACCOUNT: "",
      VERIFICATION_ERROR_HEADER_TITLE: "",
      ID_LABEL: "",
      ID_PLACEHOLDER: "",
      ID_ERROR_MESSAGE: "",
      CHOOSE_ID_TYPE_TITLE: "",
      ACCOUNT_LABEL: "",
      ACCOUNT_PLACEHOLDER: "",
      ACCOUNT_ERROR_MESSAGE: "",
      REMEMBER_ME: "",
      CANCEL: "",
      SUBMIT: "",
      WHY_NEED_THIS: "",
      WHY_NEED_THIS_MESSAGE: "",
      SAMPLE: "",
      SAMPLE_POPUP_ACCOUNT_IMAGE_HEADER: "",
      SAMPLE_POPUP_ACCOUNT_IMAGE_MESSAGE: "",
      SAMPLE_POPUP_ACCOUNT_IMAGE_ALT: "",
      SAMPLE_POPUP_SMARTCARD_IMAGE_HEADER: "",
      SAMPLE_POPUP_SMARTCARD_IMAGE_MESSAGE: "",
      SAMPLE_POPUP_SMARTCARD_IMAGE_ALT: "",
    },
    errors: {
      ALREADY_REDEEM: "",
      COUPON_NOT_AVAILABLE: "",
      PROMOTION_COUPON_NOT_AVAILABLE: "",
      COUPON_FINISHED: "",
      PROMOTION_COUPON_FINISHED: "",
      GENERIC_COUPON_STATUS_FAILED_MESSAGE: "",
      CONTACT_NOT_FOUND: "",
      VALIDATION_ERROR: "",
      INVALID_ACCOUNT_NUMBER: "",
      REDEEM_FORBIDDEN: "",
      INVALID_SMC_NUMBER: "",
      DES_SIGNATURE_FAILURE: "",
      DES_INVALID_RESPONSE: "",
      DES_REQUEST_FAILED: "",
      REDEEM_API_FAILED: "",
      GENERIC_USER_VERIFICATION_FAILED_HEADER: "",
      GENERIC_USER_VERIFICATION_FAILED_MESSAGE: "",
      UNABLE_TO_PROCEED_MESSAGE: "",
      UNABLE_TO_PROCEED_HEADER: "",
      SOMETHING_WENT_WRONG_HEADER: "",
      SOMETHING_WENT_WRONG_MESSAGE: "",
    },
    integrations: {},
    shortForm: {
      RETRY: "",
      THANK_YOU: "",
      FULLNAME: "",
      ACCOUNT_SMC: "",
      MOBILE_NUMBER: "",
      ALT_NUMBER: "",
      MYKAD_OR_PASSPORT_NUMBER: "",
      EMAIL: "",
      WHERE_TO_FIND: "",
      FULLNAME_PLACEHOLDER: "",
      ACC_SMC_PLACEHOLDER: "",
      MOBILE_NUM_PLACEHOLDER: "",
      ALT_NUM_PREFIX: "",
      ALT_NUM_PLACEHOLDER: "",
      EMAIL_PLACEHOLDER: "",
      OPEN_ENDED_PLACEHOLDER: "",
      MYKAD_PLACEHOLDER: "",
      PASSPORT_PLACEHOLDER: "",
      MYKAD: "",
      PASSPORT: "",
      ENTER_VALID: "",
      FIELD_IS_REQUIRED: "",
      MAX_LEN_OPEN_QUESTION: "",
      SUBMIT: "",
      TERMS_AND_CONDITIONS: "",
      SELECT: "",
      WHERE_TO_FIND_TITLE: "",
      FIND_SUBTITLE1: "",
      FIND_SUBTITLE2: "",
      FIND_IMAGE: "",
      PROMOCODE: "",
      APPLY: "",
      REMOVE: "",
      APPLIED: "",
      PROMOCODE_PLACEHOLDER: "",
      PROMOCODE_MAXLEN: "",
      PROMOCODE_ERROR: "",
      ERROR_IN_CONTACT_NUMBERS: "",
    },
  },
  meta: {
    homeTitle:
      PORTAL === "rewards"
        ? "Astro Rewards - Exclusive Privileges and Offers for Astro Subscribers"
        : "Astro Promotions - Special Deals for New & Existing Customers",
    homeDesc:
      PORTAL === "rewards"
        ? "Enjoy a wide range of exciting privileges, discounts and gifts exclusively for Astro customers. Check out the latest rewards and redeem now!"
        : "Find out the latest Astro offers including packages, Astro & Broadband, pay-per-view, movie collections for more savings!",
    homeOGType: "",
    detailsTitle: PORTAL === "rewards" ? "Astro Rewards" : "Astro Promotions",
    detailsDesc: "",
    detailsOGType: "",
    thumbnail: "",
    twitterCard: "",
    twitterSite: "",
  },
  resizer: {
    url: "https://resizer.eco.astro.com.my",
    config: {
      default: "",
      detail: "tr:w-1000",
      card_landscape: "tr:w-768",
      coupon_image: "tr:w-592",
      vendor: "tr:w-48,h-48",
      listing_image: "tr:w-368,h-207",
      banner_mobile: "tr:w-432,h-432",
    },
  },
  portal: null,
};

const configReducer: Reducer<IConfigState, ConfigActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case GET_CONFIG:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case GET_CONFIG_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
      };
    case GET_CONFIG_SUCCESS:
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    default:
      return state;
  }
};

export default configReducer;

// Actions
export const getConfig: ActionCreator<ThunkAction<void, any, void, any>> =
  () => async (dispatch) => {
    const selectedLanguage = store.getState().listing.language;

    dispatch({ type: GET_CONFIG });
    try {
      const response = await axios.get<IFortressResponse<any>>(
        settings.config.url
      );
      const { data } = response;
      const payload: IConfigPayload = {};
      const appConfig = extractConfig<IAppConfig>(
        settings.config.key.app,
        data
      );
      if (appConfig) payload.app = appConfig;

      //To get the Query params of url when ?app=1
      const params = new URLSearchParams(window.location.search);
      if (payload.app) payload.app.isApp = params.get("app") === "1";

      let listingPageConfig = extractConfig<IListingPage>(
        settings.config.key.listing,
        response.data
      );
      if (listingPageConfig) {
        if (PORTAL === "rewards") {
          if (listingPageConfig._rewards_)
            listingPageConfig = {
              ...listingPageConfig,
              ...listingPageConfig._rewards_,
            };
        } else if (PORTAL === "promotions") {
          if (listingPageConfig._promotions_)
            listingPageConfig = {
              ...listingPageConfig,
              ...listingPageConfig._promotions_,
            };
        }
        payload.listing = listingPageConfig;
      }

      const verificationPageConfig = extractConfig<IVerificationPage>(
        settings.config.key.verification,
        response.data
      );
      if (verificationPageConfig) payload.verification = verificationPageConfig;

      const languageConfig = extractConfig<ILanguages>(
        settings.config.key.language,
        response.data
      );

      if (languageConfig) {
        if (languageConfig[selectedLanguage]) {
          let language = languageConfig[selectedLanguage];
          if (PORTAL === "rewards") {
            if (language._rewards_)
              language = {
                ...language,
                ...language._rewards_,
              };
          } else if (PORTAL === "promotions") {
            if (language._promotions_)
              language = {
                ...language,
                ...language._promotions_,
              };
          }
          payload.language = language;
        }
      }

      let metaConfig = extractConfig<IMetaConfig>(
        settings.config.key.meta,
        response.data
      );
      if (metaConfig) {
        if (PORTAL === "rewards") {
          if (metaConfig._rewards_)
            metaConfig = {
              ...metaConfig,
              ...metaConfig._rewards_,
            };
        } else if (PORTAL === "promotions") {
          if (metaConfig._promotions_)
            metaConfig = {
              ...metaConfig,
              ...metaConfig._promotions_,
            };
        }
        payload.meta = metaConfig;
      }

      const resizerConfig = extractConfig<IResizerConfig>(
        settings.config.key.resizer,
        data
      );
      if (resizerConfig) {
        payload.resizer = resizerConfig;
      }

      const portalConfig = extractConfig<IPortalConfig>(
        settings.config.key.portal,
        data
      );
      if (portalConfig) {
        payload.portal = get(portalConfig, PORTAL, null);
      }

      dispatch({
        type: GET_CONFIG_SUCCESS,
        payload,
      });
    } catch (e) {
      dispatch({ type: GET_CONFIG_ERROR });
    }
  };
