export interface IItem {
  cmsId: number;
  type: ITEMTYPE;
  name: string;
  title: string;
  code: string;
  imageUrl: string;
  vendor: IVendor;
  locationText: string;
  locationLink: string;
  website: string;
  description: string;
  highlight: string;
  price: string | null;
  isFlashSale: boolean;
  isVisibleOnListing: boolean;
  showRedemptionPeriod?: boolean;
  cta: string | null;
  redeemType: REDEEMTYPE;
  redemptionFrom: string;
  redemptionTo: string;
  scheduleFrom: string;
  scheduleTo: string;
  couponValidity: number;
  showTimer: boolean;
  codeNotRequired: boolean;
  displayQRCode: boolean;
  leadFormRequired: boolean;
  leadFormFields: string;
  leadFormFieldsArrangement: string;
  offerCode: string;
  termsLink: string;
  hasStarted?: boolean;
  hasExpired?: boolean;
  categories: ICategory[];
  enableGwe: boolean;
  requireCouponVerification: boolean;
  requireThirdPartyIntegration: boolean;
  typeOfIntegration: INTEGRATION_TYPE;
  thankYouMessage: string;
  confirmationMessage: string;
  videoUrl: string;
  secondaryBtnText: string;
  secondaryBtnCtaUrl: string;
  OpenEndedQuestion: string;
  exploreLatestPromotion: boolean;
  dropdownListOne: IDropdown;
  dropdownListTwo: IDropdown;
  dropdownListThree: IDropdown;
  leadFormDescription: string;
  leadFormTitle: string;
  ctaButtonText: string;
}

export interface IDropdown {
  Options: string[];
  Title: string;
}

export interface ICategory {
  id: number;
  code: string;
}

export enum ITEMTYPE {
  all = "all",
  reward = "reward",
  promotion = "promotion",
}

export enum LANGUAGE {
  bm = "may",
  en = "eng",
}

export enum INTEGRATION_TYPE {
  direct = "direct",
  iqiyi = "iqiyi",
}

export enum REDEEMTYPE {
  online = 1,
  offline,
}

interface IVendor {
  cmsId: number;
  name: string;
  phone: string;
  logo: string;
  email: string;
}

export type SortOrder = "asc" | "desc";

export interface ISortOption {
  sortBy: string;
  sortOrder: SortOrder;
}

export enum ERRORTYPE {
  notFound = 1,
  validation,
  error,
}

export interface IErrorModalState {
  open: boolean;
  title: string;
  message: string;
}

export class ErrorModalState implements IErrorModalState {
  open = false;
  title = "";
  message = "";
}
