import "intersection-observer";
import React from "react";
import dotenv from "dotenv";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";

import "./style.css";
import App from "@app";
import configureStore from "./store/index";
import reportWebVitals from "./reportWebVitals";
import { vitalsMetricHandler } from "./analytics/gtm";

dotenv.config();

export const store = configureStore();

const container = document.getElementById("bmV0aXplbmFjbS1yZXdhcmRz");
const root = createRoot(container!); 

root.render(
  <Provider store={store}>
    <Router>
      <App />
    </Router>
  </Provider>
);

reportWebVitals(vitalsMetricHandler);
